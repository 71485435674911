import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { use100vh } from "react-div-100vh";
import Modal from "react-modal";
import { RemoveScroll } from "react-remove-scroll";
import { useForm } from "react-hook-form";
import { GatsbyImage } from "gatsby-plugin-image";

// Utils
import { encodeForm } from "../utils/encode-form";

// Icons
import { CloseIcon } from "../icons/slideshow-icons";

const LightboxContainer = styled.div`
  background-color: #f5eadf;
  color: #0b646f;
  padding: 0 20px;

  z-index: 100;

  height: 100%;
  width: 100%;

  & .lightbox-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: calc(${props => props.height}px - 50px - 50px);
    position: relative;
  }

  & .top-navigation {
    display: grid;
    grid-template-columns: 300px 1fr 300px;
    padding: 0 25px;

    & h1 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      font-size: 18px;
      line-height: 50px;
      letter-spacing: 0;

      color: #0b646f;

      & .small {
        font-size: 10px;
        padding: 0 10px;
        letter-spacing: 0.02em;
      }

      /* font-size: 22px;

      & .small {
        font-size: 10px;
        padding: 0 10px;
      } */
    }

    @media (max-width: 960px) {
      grid-template-columns: 20px 1fr 20px;
      padding: 0;

      & h1 {
        font-size: 14px;
        line-height: 30px;

        & .small {
          font-size: 6px;
          padding: 0 6px;
        }
      }
    }
  }
`;

const EnquiryFormContainer = styled.div`
  max-width: 415px;
  width: 100%;
  padding: 40px 0;

  & .top-fields {
    & h1 {
      font-size: 12px;
      line-height: 20px;

      margin: 0 0 20px 0;
    }

    & h2 {
      font-size: 15px;
      line-height: 20px;
    }

    & .image-container {
      max-width: 180px;
      margin: 16px 0 39px 0;
      padding: 10px;
      background: #fff;

      & .gatsby-image-wrapper {
        background: #fff;
        height: fit-content;
      }

      & img {
        object-fit: contain !important;
      }
    }
  }

  & form {
    margin: 0 auto;

    & .field {
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;

      border-bottom: 1px solid #debb9b;

      margin: 0 0 15px 0;

      & input,
      & textarea {
        width: 100%;

        padding: 0;
        margin: 0;
        border: 0;

        color: #0b646f;
        background: transparent;

        font-size: 15px;
        line-height: 28px;
        letter-spacing: 0.04em;

        font-family: "NewsGothicMTProBold", "Helvetica Neue", "Lucida Grande",
          sans-serif;

        ::-webkit-input-placeholder {
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.02em;

          text-transform: uppercase;
          color: #0b646f;

          font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue",
            "Lucida Grande", sans-serif;
        }
        ::-moz-placeholder {
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.02em;

          text-transform: uppercase;
          color: #0b646f;

          font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue",
            "Lucida Grande", sans-serif;
        }
        :-ms-input-placeholder {
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.02em;

          text-transform: uppercase;
          color: #0b646f;

          font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue",
            "Lucida Grande", sans-serif;
        }
        :-moz-placeholder {
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.02em;

          text-transform: uppercase;
          color: #0b646f;

          font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue",
            "Lucida Grande", sans-serif;
        }
      }
    }

    & .privacy-container {
      display: grid;
      grid-template-columns: 20px auto;
      grid-column-gap: 20px;
      /* align-items: baseline; */
      line-height: 16px;
      margin: 30px 0 0 0;

      color: #0b646f;

      & p {
        font-size: 15px;
        line-height: 16px;
        letter-spacing: 0.02em;

        margin: 0;

        & a {
          color: #c59a72;
        }

        @media (max-width: 1440px) {
          font-size: 14px;
          line-height: 20px;
        }

        @media (max-width: 500px) {
          font-size: 10px;
          line-height: 14px;
        }
      }

      & .gdpr-checkbox {
        width: 13px;
        height: 13px;

        position: relative;

        @media (max-width: 500px) {
          width: 11px;
          height: 11px;
        }
      }

      & .gdpr-checkbox label {
        width: 13px;
        height: 13px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid #debb9b;

        @media (max-width: 500px) {
          width: 11px;
          height: 11px;
        }
      }

      & .gdpr-checkbox label:after {
        content: "";
        width: 7px;
        height: 3px;
        position: absolute;
        top: 4px;
        left: 3px;
        border: 1px solid #debb9b;
        border-top: none;
        border-right: none;
        background: transparent;
        opacity: 0;
        transform: rotate(-45deg);

        @media (max-width: 500px) {
          width: 6px;
          height: 3px;
          top: 3px;
          left: 2px;
        }
      }

      & .gdpr-checkbox label:hover::after {
        opacity: 1;
      }

      & .gdpr-checkbox input[type="checkbox"] {
        width: 13px;
        height: 13px;

        visibility: hidden;

        @media (max-width: 500px) {
          width: 11px;
          height: 11px;
        }
      }

      & .gdpr-checkbox input[type="checkbox"]:checked + label:after {
        opacity: 1;
      }

      & .errors {
        margin: 10px 0 0 0;
        grid-column: span 2;

        & p {
          font-size: 13px;
          line-height: 17px;

          color: rgba(0, 0, 0, 0.4);

          @media (max-width: 768px) {
            font-size: 12px;
            line-height: 17px;
          }
        }
      }
    }

    & .submit-button {
      display: block;

      font-size: 15px;
      line-height: 39px;
      letter-spacing: 0.02em;

      color: #0b646f;
      background-color: #fff;

      width: 100%;
      height: 40px;
      margin: 47px 0 0 0;

      @media (hover: hover) and (pointer: fine) {
        transition: 250ms background-color ease, 250ms color ease;

        &:hover {
          color: #fff;
          background-color: #0b646f;
        }
      }

      @media (max-width: 800px) {
        font-size: 14px;
        line-height: 20px;
      }

      @media (max-width: 500px) {
        font-size: 11px;
        line-height: 15px;
      }
    }
  }

  & .thank-you-message {
    margin: 100px 0 0 0;
    text-align: center;

    & p {
      color: #0b646f;
      margin: 0;
    }
  }
`;

const CloseLightbox = styled.button`
  position: absolute;
  top: 0;
  right: 0;

  z-index: 200;

  font-size: 1;
  line-height: 1;
  letter-spacing: 0;

  padding: 16px 25px;

  & svg {
    & path {
      transition: 250ms fill ease;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      & svg {
        & path {
          fill: #dbaf96;
        }
      }
    }
  }

  @media (max-width: 960px) {
    padding: 8px 10px;
    height: 29px;

    & svg {
      width: 12px;
      height: 12px;
    }
  }
`;

Modal.setAppElement("#___gatsby");

export const ProductEnquiryForm = ({
  title,
  url,
  artist,
  isProductEnquiryFormOpen,
  setIsProductEnquiryFormOpen,
  image,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [formMessage, setFormMessage] = useState(null);
  const [checked, setChecked] = useState(false);
  const height = use100vh();
  const subjectLine = `House of Voltaire - Artwork Enquiry: ${artist} | ${title}`;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      url,
      message: "",
      privacyPolicyAgreed: false,
      subject: subjectLine,
    },
  });

  const onSubmit = async (data, e) => {
    console.log("enquiry");
    e.preventDefault();
    try {
      console.log("TRY");

      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encodeForm({
          "form-name": "product-enquiry",
          ...data,
        }),
      });
      setIsFormVisible(false);
      setFormMessage(
        "Thank you for your interest. We will get back to you within 1-2 working days."
      );
      reset();
    } catch (error) {
      console.error("error:", error);
      setIsFormVisible(false);
      setFormMessage(
        "An error occurred while submitting your enquiry. Please email us directly."
      );
    }
  };

  useEffect(() => {
    if (!isFormVisible) {
      const closeForm = setTimeout(() => {
        setIsFormVisible(false);
        const fadeInForm = setTimeout(() => {
          setIsFormVisible(true);
        }, 750);
        return () => clearTimeout(fadeInForm);
      }, 8000);
      return () => clearTimeout(closeForm);
    }
  }, [isFormVisible]);

  return (
    <RemoveScroll>
      <Modal
        isOpen={isProductEnquiryFormOpen}
        portalClassName="ReactModalPortal-mailchimp"
        contentLabel="Mailchimp Signup Form"
        shouldFocusAfterRender={true}
      >
        <LightboxContainer height={height}>
          <div className="top-navigation">
            <div className="spacer" />
            <Link to="/">
              <h1 className="logo uppercase">
                House
                <span className="small">of</span>
                Voltaire
              </h1>
            </Link>
            <CloseLightbox onClick={() => setIsProductEnquiryFormOpen(false)}>
              <CloseIcon />
            </CloseLightbox>
          </div>

          <div className="lightbox-wrapper">
            <EnquiryFormContainer className="enquire-form">
              <div className="inner-form-container">
                {isFormVisible ? (
                  <form
                    name="product-enquiry"
                    method="POST"
                    data-netlify="true"
                    netlify-honeypot="bot-field"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="top-fields">
                      <h1>Enquire About This Artwork</h1>
                      <h2>
                        <span className="artist-name uppercase">{artist}</span>
                        <br />
                        <span className="artwork-title">{title}</span>
                      </h2>
                      <div className="image-container">
                        <GatsbyImage
                          image={image}
                          alt={title}
                          draggable="false"
                          loading="lazy"
                        />
                      </div>
                    </div>

                    <input
                      type="hidden"
                      name="form-name"
                      value="product-enquiry"
                    />
                    <input type="hidden" name="bot-field" />
                    <input {...register("url")} type="hidden" />
                    <input {...register("subject")} type="hidden" />

                    <div className="field">
                      <input
                        {...register("firstName", { required: true })}
                        type="text"
                        autoComplete="given-name"
                        aria-required="true"
                        aria-label="First Name"
                        placeholder={
                          errors.firstName
                            ? "Enter a valid first name"
                            : "First Name"
                        }
                      />
                    </div>

                    <div className="field">
                      <input
                        {...register("lastName", { required: true })}
                        type="text"
                        autoComplete="family-name"
                        aria-required="true"
                        aria-label="Last Name"
                        placeholder={
                          errors.lastName
                            ? "Enter a valid last name"
                            : "Last Name"
                        }
                      />
                    </div>

                    <div className="field">
                      <input
                        {...register("email", { required: true })}
                        type="email"
                        autoComplete="email"
                        aria-required="true"
                        aria-label="E-mail"
                        placeholder={
                          errors.email
                            ? "Enter a valid email address"
                            : "Email Address"
                        }
                      />
                    </div>

                    <div className="field">
                      <textarea
                        {...register("message", { required: true })}
                        rows="4"
                        placeholder="Message"
                      />
                    </div>

                    <div className="privacy-container">
                      <div className="gdpr-checkbox">
                        {/* Hidden checkbox for custom styling */}
                        <input
                          type="checkbox"
                          id="gdpr-checkbox"
                          {...register("privacyPolicyAgreed", {
                            required: true,
                          })}
                          style={{ visibility: "hidden" }} // Keep checkbox hidden but functional
                        />

                        {/* Label for custom checkbox styling */}
                        <label htmlFor="gdpr-checkbox"></label>
                      </div>

                      <p className="news-gothic">
                        You agree to our{" "}
                        <Link to="/privacy-policy/">privacy policy</Link>
                      </p>

                      {errors.privacyPolicyAgreed && (
                        <div className="errors">
                          <p className="news-gothic">
                            Please tick this box to proceed
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="buttons-container">
                      <button type="submit" className="submit-button">
                        Submit
                      </button>
                    </div>
                  </form>
                ) : (
                  <div
                    className="thank-you-message news-gothic"
                    id="thank_you_message"
                  >
                    <p>{formMessage}</p>
                  </div>
                )}
              </div>
            </EnquiryFormContainer>
          </div>
        </LightboxContainer>
      </Modal>
    </RemoveScroll>
  );
};
