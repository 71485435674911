import React, { forwardRef } from "react";
import { Controller } from "react-hook-form";

export const Checkbox = forwardRef(
  ({ control, name, label, value, id, ...rest }, ref) => {
    return (
      <Controller
        control={control}
        name={name}
        defaultValue={false}
        render={({ field: { onChange, value: fieldValue } }) => (
          <div
            className="mailchimp-group-toggle"
            onClick={() => onChange(!fieldValue)}
          >
            <input
              ref={ref}
              type="checkbox"
              id={id}
              checked={fieldValue}
              onChange={e => onChange(e.target.checked)}
              value={value}
              {...rest}
            />
            <label htmlFor={name} />
          </div>
        )}
      />
    );
  }
);
