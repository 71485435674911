import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";
import { use100vh } from "react-div-100vh";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import addToMailchimp from "gatsby-plugin-mailchimp";

// Hooks
// import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Context
import { PageSetup } from "../components/context/page-context";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Icons
import { CloseIcon } from "../components/icons/slideshow-icons";

// SEO
import { PageSeo } from "../components/seo/page-seo";
import { Checkbox } from "../components/forms/checkbox";

const Page = styled.div`
  min-height: ${props => props.height}px;
  padding: 0 30px;

  @media (max-width: 768px) {
    padding: 0 20px;
  }

  & .top-navigation {
    display: grid;
    grid-template-columns: 300px 1fr 300px;
    padding: 0 25px;

    & h1 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      font-size: 18px;
      line-height: 50px;
      letter-spacing: 0;

      color: #0b646f;

      & .small {
        font-size: 10px;
        padding: 0 10px;
        letter-spacing: 0.02em;
      }
    }

    @media (max-width: 960px) {
      grid-template-columns: 20px 1fr 20px;
      padding: 0;

      & h1 {
        font-size: 14px;
        line-height: 30px;

        & .small {
          font-size: 6px;
          padding: 0 6px;
        }
      }
    }
  }

  /* padding: 51px 0;

  @media (max-width: 768px) {
    padding: 30px 0;
  } */
`;

const LightboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: #f5eadf;

  z-index: 100;
  height: 100%;
  width: 100%;

  min-height: calc(${props => props.height}px - 51px - 51px);

  & .lightbox-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    position: relative;
  }
`;

const SignupFormContainer = styled.div`
  max-width: 415px;
  width: 100%;
  padding: 40px 0;

  & form {
    margin: 0 auto;

    & .field {
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;

      border-bottom: 1px solid #debb9b;

      margin: 0 0 15px 0;

      & .preference-container {
        margin: 4px 0 13px 0;

        & p {
          font-size: 12px;
          text-transform: uppercase;
          color: #0b646f;

          margin: 0 0 10px 0;
        }

        & .single-preference {
          display: grid;
          grid-template-columns: 20px auto;
          grid-column-gap: 20px;
          /* margin: 0 0 10px 0; */

          & .mailchimp-group-toggle {
            width: 13px;
            height: 13px;

            position: relative;

            @media (max-width: 500px) {
              width: 11px;
              height: 11px;
            }
          }

          & .mailchimp-group-toggle label {
            width: 13px;
            height: 13px;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            border: 1px solid #debb9b;

            @media (max-width: 500px) {
              width: 11px;
              height: 11px;
            }
          }

          & .mailchimp-group-toggle label:after {
            content: "";
            width: 7px;
            height: 3px;
            position: absolute;
            top: 4px;
            left: 3px;
            border: 1px solid #debb9b;
            border-top: none;
            border-right: none;
            background: transparent;
            opacity: 0;
            transform: rotate(-45deg);

            @media (max-width: 500px) {
              width: 6px;
              height: 3px;
              top: 3px;
              left: 2px;
            }
          }

          & .mailchimp-group-toggle label:hover::after {
            opacity: 1;
          }

          & .mailchimp-group-toggle input[type="checkbox"] {
            width: 13px;
            height: 13px;

            visibility: hidden;

            @media (max-width: 500px) {
              width: 11px;
              height: 11px;
            }
          }

          &
            .mailchimp-group-toggle
            input[type="checkbox"]:checked
            + label:after {
            opacity: 1;
          }

          & p {
            font-size: 15px;
            line-height: 17px;
            letter-spacing: 0.02em;
            color: #0b646f;

            text-transform: initial;

            @media (max-width: 500px) {
              font-size: 12px;
              line-height: 17px;
            }
          }
        }
      }

      & input {
        width: 100%;

        padding: 0;
        margin: 0;
        border: 0;

        color: #0b646f;
        background: transparent;

        font-size: 15px;
        line-height: 28px;
        letter-spacing: 0.04em;

        font-family: "NewsGothicMTProBold", "Helvetica Neue", "Lucida Grande",
          sans-serif;

        ::-webkit-input-placeholder {
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.02em;

          text-transform: uppercase;
          color: #0b646f;

          font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue",
            "Lucida Grande", sans-serif;
        }
        ::-moz-placeholder {
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.02em;

          text-transform: uppercase;
          color: #0b646f;

          font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue",
            "Lucida Grande", sans-serif;
        }
        :-ms-input-placeholder {
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.02em;

          text-transform: uppercase;
          color: #0b646f;

          font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue",
            "Lucida Grande", sans-serif;
        }
        :-moz-placeholder {
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.02em;

          text-transform: uppercase;
          color: #0b646f;

          font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue",
            "Lucida Grande", sans-serif;
        }
      }
    }

    & .privacy-container {
      display: grid;
      grid-template-columns: 20px auto;
      grid-column-gap: 20px;
      /* align-items: baseline; */
      line-height: 16px;
      margin: 30px 0 0 0;

      color: #0b646f;

      & p {
        font-size: 15px;
        line-height: 16px;
        letter-spacing: 0.02em;

        margin: 0;

        & a {
          color: #c59a72;
        }

        @media (max-width: 1440px) {
          font-size: 14px;
          line-height: 20px;
        }

        @media (max-width: 500px) {
          font-size: 10px;
          line-height: 14px;
        }
      }

      & .gdpr-checkbox {
        width: 13px;
        height: 13px;

        position: relative;

        @media (max-width: 500px) {
          width: 11px;
          height: 11px;
        }
      }

      & .gdpr-checkbox label {
        width: 13px;
        height: 13px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid #debb9b;

        @media (max-width: 500px) {
          width: 11px;
          height: 11px;
        }
      }

      & .gdpr-checkbox label:after {
        content: "";
        width: 7px;
        height: 3px;
        position: absolute;
        top: 4px;
        left: 3px;
        border: 1px solid #debb9b;
        border-top: none;
        border-right: none;
        background: transparent;
        opacity: 0;
        transform: rotate(-45deg);

        @media (max-width: 500px) {
          width: 6px;
          height: 3px;
          top: 3px;
          left: 2px;
        }
      }

      & .gdpr-checkbox label:hover::after {
        opacity: 1;
      }

      & .gdpr-checkbox input[type="checkbox"] {
        width: 13px;
        height: 13px;

        visibility: hidden;

        @media (max-width: 500px) {
          width: 11px;
          height: 11px;
        }
      }

      & .gdpr-checkbox input[type="checkbox"]:checked + label:after {
        opacity: 1;
      }

      & .errors {
        margin: 10px 0 0 0;
        grid-column: span 2;

        & p {
          font-size: 13px;
          line-height: 17px;

          color: rgba(0, 0, 0, 0.4);

          @media (max-width: 768px) {
            font-size: 12px;
            line-height: 17px;
          }
        }
      }
    }

    & .submit-button {
      display: block;

      font-size: 15px;
      line-height: 39px;
      letter-spacing: 0.02em;

      color: #0b646f;
      background-color: #fff;

      width: 100%;
      height: 40px;
      margin: 47px 0 0 0;

      transition: 250ms background-color ease, 250ms color ease;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: #fff;
          background-color: #0b646f;
        }
      }

      @media (max-width: 800px) {
        font-size: 14px;
        line-height: 20px;
      }

      @media (max-width: 500px) {
        font-size: 11px;
        line-height: 15px;
      }
    }
  }

  & .thank-you-message {
    margin: 70px 0 0 0;

    & p {
      color: #0b646f;
    }
  }
`;

const CloseLightbox = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  z-index: 200;

  font-size: 1;
  line-height: 1;
  letter-spacing: 0;

  padding: 16px 25px;

  & svg {
    & path {
      transition: 250ms fill ease;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      & svg {
        & path {
          fill: #dbaf96;
        }
      }
    }
  }

  @media (max-width: 960px) {
    padding: 8px 10px;
    height: 29px;

    & svg {
      width: 12px;
      height: 12px;
    }
  }
`;

Modal.setAppElement("#___gatsby");

const MailingList = ({ location }) => {
  const height = use100vh();
  const [backButtonAsLink, setBackButtonAsLink] = useState(true);
  const [pageSetup, setPageSetup] = useContext(PageSetup);
  const [formMessage, setFormMessage] = useState("");

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      fName: "",
      lName: "",
      email: "",
      groupOne: false,
      groupTwo: false,
      privacyPolicyAgreed: false,
    },
  });

  useEffect(() => {
    setPageSetup({
      pageTitle: "",
      pageColor: "#f5eadf",
      pageType: "delivery",
      filterArtists: [],
      selectedArtists: [],
      sortOption: null,
      priceOption: null,
      artistOption: [],
      applyFilter: false,
    });
  }, []);

  const onSubmit = async (data, e) => {
    try {
      const response = await addToMailchimp(data.email, {
        FNAME: data.fName,
        LNAME: data.lName,
        ...(data.groupOne && { "group[5045][1]": "1" }),
        ...(data.groupTwo && { "group[5045][2]": "2" }),
      });

      if (
        response.result === "error" &&
        response.msg.includes("already subscribed")
      ) {
        setFormMessage(
          "Thank you for signing up to the House of Voltaire mailing list."
        );
        reset();
      } else {
        setFormMessage(
          "Thank you for signing up to the House of Voltaire mailing list."
        );
        reset();
      }
    } catch (error) {
      console.error("Subscription error:", error);
    }
  };

  useEffect(() => {
    if (formMessage) {
      const closeForm = setTimeout(() => {
        setFormMessage("");
      }, 8000);
      return () => clearTimeout(closeForm);
    }
  }, [formMessage]);

  useEffect(() => {
    if (location?.state?.from === "website") {
      setBackButtonAsLink(false);
    }
  }, [location]);

  return (
    <>
      <PageSeo seoTitle="Mailing List" seoText={null} seoImage={null} />

      <Page height={height}>
        <div className="top-navigation">
          <div className="spacer" />
          <Link to="/">
            <h1 className="logo uppercase">
              House
              <span className="small">of</span>
              Voltaire
            </h1>
          </Link>

          <CloseLightbox>
            {backButtonAsLink ? (
              <Link to="/">
                <CloseIcon />
              </Link>
            ) : (
              <button onClick={() => navigate(-1)}>
                <CloseIcon />
              </button>
            )}
          </CloseLightbox>
        </div>

        <LightboxContainer height={height}>
          <div className="lightbox-wrapper">
            <SignupFormContainer>
              {!formMessage ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="field">
                    <input
                      {...register("fName", { required: true })}
                      type="text"
                      autoComplete="given-name"
                      aria-required="true"
                      aria-label="First Name"
                      placeholder="First name"
                    />
                  </div>

                  <div className="field">
                    <input
                      {...register("lName", { required: true })}
                      type="text"
                      autoComplete="family-name"
                      aria-required="true"
                      aria-label="Last Name"
                      placeholder="Last name"
                    />
                  </div>

                  <div className="field">
                    <input
                      {...register("email", { required: true })}
                      type="email"
                      autoComplete="email"
                      aria-required="true"
                      aria-label="Email"
                      placeholder="Email"
                    />
                  </div>

                  <div className="field">
                    <div className="preference-container">
                      <p>Set your preference</p>

                      <div className="single-preference">
                        <Checkbox
                          control={control}
                          name="groupOne"
                          id="mce-group[5045]-5045-0"
                          value="1"
                        />
                        <p className="news-gothic">Studio Voltaire News</p>
                      </div>

                      <div className="single-preference">
                        <Checkbox
                          control={control}
                          name="groupTwo"
                          id="mce-group[5045]-5045-1"
                          value="2"
                        />
                        <p className="news-gothic">
                          House of Voltaire Mailing List
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="privacy-container">
                    <div className="gdpr-checkbox">
                      <Checkbox
                        control={control}
                        name="privacyPolicyAgreed"
                        id="gdpr-checkbox"
                        value="true"
                      />
                      <label htmlFor="privacyPolicyAgreed"></label>
                    </div>

                    <p className="news-gothic">
                      You agree to our{" "}
                      <Link to="/privacy-policy/">privacy policy</Link>
                    </p>

                    {errors.privacyPolicyAgreed && (
                      <div className="errors">
                        <p className="news-gothic">
                          Please tick this box to proceed
                        </p>
                      </div>
                    )}
                  </div>

                  <button type="submit" className="submit-button">
                    Submit
                  </button>
                </form>
              ) : (
                <div className="thank-you-message">
                  <p className="news-gothic">{formMessage}</p>
                </div>
              )}
            </SignupFormContainer>
          </div>
        </LightboxContainer>
      </Page>
    </>
  );
};

export default withPrismicPreview(MailingList);
